import { css, keyframes } from '@emotion/react';

const Animation = keyframes`
  from {
    opacity: 0;
    transform: translateY(160px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;
export const bottomEnter = css`
  animation: ${Animation} 0.64s ease forwards;
  will-change: opacity, transform;
`;
