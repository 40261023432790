import { css, keyframes } from '@emotion/react';

import { topEnter, rightEnter, leftEnter, bottomEnter } from '../fx';

function getContentInFx(align) {
  switch (align) {
    case 'bottom':
    case 'bottomLeft':
    case 'bottomRight':
      return bottomEnter;
    case 'left':
      return leftEnter;
    case 'right':
      return rightEnter;
    case 'top':
    case 'topLeft':
    case 'topRight':
    default:
      return topEnter;
  }
}

export default (align, opacity) => {
  const Animation = keyframes`
    from { opacity: 0 }
    to { opacity: ${opacity} }
  `;
  return css`
    :before {
      animation: ${Animation} 1.2s ease forwards;
      will-change: opacity;
    }
    > * {
      ${getContentInFx(align)}
    }
  `;
};
