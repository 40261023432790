/** @jsx jsx */
import { css, keyframes } from '@emotion/react';

const defaultHeight = 24;
const defaultWidth = 40;
const defaultOffset = (defaultWidth - defaultHeight) / 2;
const defaultDistortion = 2;
const defaultSpeed = '0.4s';

const toggleOn = keyframes`
  50% {
    width: ${defaultHeight + defaultDistortion}px;
  }
  from {
    transform: translateX(-${defaultOffset}px);
  }
  to {
    transform: translateX(${defaultOffset}px);
  }
`;

const toggleOff = keyframes`
  50% {
    width: ${defaultHeight + defaultDistortion}px;
  }
  from {
    transform: translateX(${defaultOffset}px);
  }
  to {
    transform: translateX(-${defaultOffset}px);
  }
`;

export const toggleCss =
  ({ pushLeft, pushRight, isMounted, isOn }) =>
  ({ color }) =>
    [
      css`
        background-color: ${isOn ? color.primary : color.gray2};
        border: 1px solid ${color.gray2};
        border-radius: ${defaultHeight / 2}px;
        height: ${defaultHeight}px;
        line-height: ${defaultHeight}px;
        padding: 0;
        transition: background-color ${defaultSpeed} ease;
        width: ${defaultWidth}px;
        will-change: background-color;
        :focus {
          outline: none;
          box-shadow: 0px 0px 8px 1px ${color.outline};
        }
        :disabled {
          opacity: 0.4;
          pointer-events: none;
        }
        span {
          display: inline-block;
          box-sizing: border-box;
          border-radius: ${defaultHeight / 2}px;
          background-color: ${color.gray3} !important;
          height: ${defaultHeight - 2}px;
          transform: translateX(${isOn ? defaultOffset : -defaultOffset}px);
          transition: transform ${defaultSpeed} ease;
          width: ${defaultHeight - 2}px;
          will-change: transform, width;
        }
      `,
      pushLeft &&
        `
    margin-left: 16px;
  `,
      pushRight &&
        `
    margin-right: 16px;
  `,
      isMounted &&
        css`
          span {
            animation: ${isOn ? toggleOn : toggleOff} ${defaultSpeed} ease forwards;
          }
        `,
    ];
