import { css, keyframes } from '@emotion/react';

const Animation = keyframes`
  to {
    opacity: 0;
    transform: translateX(160px);
  }
`;
export const rightLeave = css`
  animation: ${Animation} 0.4s ease forwards;
  will-change: opacity, transforml;
`;
