import { css, keyframes } from '@emotion/react';

const emphase = keyframes`
  0% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
`;

export const globalCss = css`
  .headline > span {
    animation: ${emphase} 0.8s ease 3;
    animation-delay: 1.6s;
    will-change: box-shadow;
  }
`;

export const localCss = (theme) => css`
  font-size: ${theme.font.size.main};
`;
