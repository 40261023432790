/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/react';

const animation = keyframes`
  0% {
    background-size: 200% 100%;
    background-position: left -31.25% top 0%;
  }
  50% {
    background-size: 800% 100%;
    background-position: left -49% top 0%;
  }
  100% {
    background-size: 400% 100%;
    background-position: left -102% top 0%;
  }
`;

const styles = (theme) => css`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 4px;
  color: ${theme.color.color1};
  background-color: ${theme.color.gray2};
  font-size: 16px;
  width: 100%;
  will-change: background;

  &::-webkit-progress-bar {
    background-color: transparent;
  }
  &::-webkit-progress-value {
    background-color: currentColor;
    transition: all 0.2s;
  }
  &::-moz-progress-bar {
    background-color: currentColor;
    transition: all 0.2s;
  }
  &::-ms-fill {
    border: none;
    background-color: currentColor;
    transition: all 0.2s;
  }
  &:indeterminate {
    animation: ${animation} 2s infinite linear;
    background-size: 200% 100%;
    background-image: linear-gradient(
      to right,
      transparent 50%,
      currentColor 50%,
      currentColor 60%,
      transparent 60%,
      transparent 71.5%,
      currentColor 71.5%,
      currentColor 84%,
      transparent 84%
    );
  }
  &:indeterminate::-moz-progress-bar {
    background-color: transparent;
  }
  &:indeterminate::-ms-fill {
    animation-name: none;
  }
`;

const LinearPropgress = () => <progress css={styles} />;

export default LinearPropgress;
