import { css, keyframes } from '@emotion/react';

const animation = keyframes`
  from {
    transform: translateY(40px);
  }
  to {
    transform: translateY(0);
  }
`;

export const contentCss = ({ color }) => css`
  color: ${color.surveyText};
  padding-bottom: 24px;
`;

export const controlsCss = (sticky) => (theme) =>
  [
    css`
      align-items: center;
      color: ${theme.color.white};
      display: flex;
      justify-content: flex-end;
      padding-top: 16px;
      .content-page__bage {
        animation: ${animation} 0.6s $easeOutExpo;
        border-radius: 10px;
        display: inline-block;
        margin-right: 16px;
        @media screen and (max-width: $mobile_breakpoint) {
          margin: auto;
          font-size: 14px;
        }
      }
      .btn-lg {
        background-color: ${theme.color.white};
        font-weight: 600;
        width: 12em;
        @media ${theme.media.phone} {
          width: 8em;
        }
      }
    `,
    sticky &&
      css`
        background-color: ${theme.color.primary};
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0px 2px 16px 5px rgba(0, 0, 0, 0.35);
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 8px;
        z-index: 2;
      `,
  ];
