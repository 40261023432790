/** @jsx jsx */
import { css, keyframes } from '@emotion/react';
import get from 'lodash/get';

const spin = keyframes`
  to { transform: rotate(360deg); }
`;

export const spinnerCss =
  ({ blockLevel, isCompact, isWhite }) =>
  ({ color }) =>
    [
      css`
        .preloader__label {
          font-size: 12px;
          line-height: 40px;
          text-align: center;
          white-space: nowrap;
        }
        .preloader__spinner {
          animation: ${spin} 0.35s linear infinite;
          border: 3px solid transparent;
          border-top-color: ${get(color, 'primary', 'currentColor')};
          border-radius: 50%;
          box-sizing: border-box;
          margin: auto;
          width: 24px;
          height: 24px;
          will-change: transform;
        }
      `,
      blockLevel &&
        css`
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        `,
      isCompact &&
        css`
          .preloader__spinner {
            border-width: 2px;
            width: 21px;
            height: 21px;
          }
        `,
      isWhite &&
        css`
          color: ${color.white};
          .preloader__spinner {
            border-top-color: rgba(255, 255, 255, 0.7);
          }
        `,
    ];
