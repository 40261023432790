import { css, keyframes } from '@emotion/react';
import { topLeave, rightLeave, leftLeave, bottomLeave } from '../fx';

export function getContentOutFx(align) {
  switch (align) {
    case 'bottom':
    case 'bottomLeft':
    case 'bottomRight':
      return bottomLeave;
    case 'left':
      return leftLeave;
    case 'right':
      return rightLeave;
    case 'top':
    case 'topLeft':
    case 'topRight':
    default:
      return topLeave;
  }
}

export default (align) => {
  const Animation = keyframes`
    to { opacity: 0 }
  `;
  return css`
    :before {
      animation: ${Animation} 0.4s ease forwards;
      will-change: opacity;
    }
    > * {
      ${getContentOutFx(align)}
      pointer-events: none;
    }
  `;
};
